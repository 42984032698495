<template>
    <form @submit.prevent="FormType == 'add' ? addComplain() : updateComplain()">
            <div class="complaint-form">
                <div class="p-fluid">
                    <div class="p-field p-grid">
                        <label for="tour_code" class="p-col-fixed">Tour code*:</label>
                        <div class="p-col">
                            <AutoComplete v-model="selectedTourCode" :suggestions="filteredTourCode" @complete="complaintTourCode($event)" @item-select="onTourCodeSelect($event)" field="tour_code" :class="{'p-invalid':v$.booking_id.$error || selectedTourCode == ''}" />
                        </div>
                    </div>
                    <div class="p-field p-grid">
                        <label for="complain_about" class="p-col-fixed">Complain about*:</label>
                        <div class="p-col">
                            <Dropdown v-model="selectedComplainAbout" @change="onComplaintAboutChange()" :options="complaintAbout" optionLabel="about" :class="{'p-invalid':v$.complain_about.$error}" >
                            </Dropdown> 
                        </div>
                    </div>
                    <div class="p-field p-grid">
                        <label for="complain_status" class="p-col-fixed">Status*:</label>
                        <div class="p-col">
                            <Dropdown v-model="selectedComplainStatus" @change="onComplaintStatusChange()" :options="complaintStatus" optionLabel="status" :class="{'p-invalid':v$.status.$error}">
                            </Dropdown> 
                        </div>
                    </div>
                    <div class="p-field p-grid">
                        <label for="report" class="p-col-fixed">Complain report*:</label>
                        <Textarea v-model="complaintForm.report" rows="10" :autoResize="true" style="margin: 0 6px" :class="{'p-invalid':v$.report.$error}" />
                    </div>
                    <div class="p-grid">
                        <div class="p-col-fixed">
                            <div v-if="FormType == 'add'">
                                <Button label="Save" type="submit" class="p-button-sm p-button-success" icon="pi pi-save" />
                            </div>
                            <div v-else>                                
                                <Button label="Update" type="submit" class="p-button-sm p-button-warning" icon="pi pi-save" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
</template>

<script>
import { ref, reactive, onMounted, computed } from 'vue';
import { useToast } from "primevue/usetoast";
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import BookingService from '../service/BookingService';
import ComplaintService from '../service/ComplaintService';

export default {
    props: {
        FormType: String
    },
    emits: ['RefreshComplaint'],
    setup(props, { emit }) {
        const toast = useToast();

        let complaintAbout = ref([
            {about: 'Hotel'},
            {about: 'Restaurant'},
            {about: 'Tour guide'},
            {about: 'Driver'},
            {about: 'Vehicle'},
            {about: 'Operations Team'},
            {about: 'Other'},
        ])
        const selectedComplainAbout = ref()
        let complaintStatus= ref([
            {status: 'Investigating'},
            {status: 'Solved'},
        ])
        const selectedComplainStatus = ref()

        const selectedTourCode = ref()
        const filteredTourCode = ref()
        let complaintForm = reactive({})

        const bookingService = ref(new BookingService())
        const complaintService = ref(new ComplaintService())

        onMounted(() => {
            initFormData()
        })

        //================Method=================
        const initFormData = () => {
            const initForm = {
                id: '',
                booking_id: '',
                complain_about: '',
                status: '',
                report: '',
            }

            selectedComplainAbout.value = ''
            selectedComplainStatus.value = ''
            // selectedTourCode.value = ''
            Object.assign(complaintForm, initForm)
        }

        const complaintTourCode = (event) => {
            setTimeout(() => {
                if (event.query.trim().length) {
                    bookingService.value.complaintTourCode(event.query.toLowerCase().trim()).then(data => {
                        if(!data.errorResponse) {
                            console.log(data)
                            if(data.length > 0) {
                                filteredTourCode.value = data
                            } else {
                                filteredTourCode.value = [{tour_code: 'No result'}]
                            }
                        }
                    })
                }
            }, 250);
        }

        const onTourCodeSelect = (event) => {
            if(event.value.id) {
                complaintForm.booking_id = event.value.id
            } else {
                selectedTourCode.value = ''
                complaintForm.booking_id = ''
            }
        }

        const onComplaintAboutChange = () => {
            complaintForm.complain_about = selectedComplainAbout.value.about
        }

        const onComplaintStatusChange = () => {
            complaintForm.status = selectedComplainStatus.value.status
        }

        const addComplain = () => {
            if(validateForm()) {
                delete complaintForm.id
                complaintService.value.addComplaint(complaintForm).then(data => {
                    if(data.errorResponse) {
                        toast.add({severity:'warn', summary: 'Add Complaint Error ' + data.status, detail: data.errorResponse, life: 5000});        
                    } else {
                        initFormData();
                        emit('RefreshComplaint')
                        toast.add({severity:'success', summary: 'Info', detail:'Complaint has been created.', life: 3000});
                        v$.value.$reset() //Reset validations
                    }
                })
            }
        }

        const editComplaint = (emitData) => {
            initFormData()

            Object.keys(complaintForm).forEach((key)=>{
                complaintForm[key] = emitData[key]
            })

            selectedComplainAbout.value = complaintAbout.value.find(item => item.about == complaintForm.complain_about)
            selectedComplainStatus.value = complaintStatus.value.find(item => item.status == complaintForm.status)
            selectedTourCode.value = emitData.tour_code
        }

        const updateComplain = () => { 
            if(validateForm()) {
                complaintService.value.updateComplaint(complaintForm.id, complaintForm).then(data => {
                    if(data.errorResponse) {
                        toast.add({severity:'warn', summary: 'Update Complaint Error ' + data.status, detail: data.errorResponse, life: 5000});        
                    } else {
                        emit('RefreshComplaint')
                        toast.add({severity:'success', summary: 'Info', detail:'Complaint has been updated.', life: 3000});
                        v$.value.$reset() //Reset validations
                    }
                })
            }
        }

        //Form Validations
        const rules = computed(() => {
            return {
                booking_id: { required },
                complain_about: { required },
                status: { required },
                report: { required },
            }            
        })
        const v$ = useVuelidate(rules, complaintForm)
        const validateForm = () => {
            v$.value.$validate();
            if(!v$.value.$error && selectedTourCode.value != ''){
                return true
            } else {
                return false
            }
        }

        return {
            complaintAbout,
            selectedComplainAbout,
            complaintStatus,
            selectedComplainStatus,
            selectedTourCode,
            filteredTourCode,
            complaintTourCode,
            onTourCodeSelect,

            v$,
            onComplaintAboutChange,
            onComplaintStatusChange,
            complaintForm,
            addComplain,
            editComplaint,
            updateComplain,
        }
    },
}
</script>

<style lang="scss" scoped>
.complaint-form {
    margin-top: 5px;

    label {
        width: 125px;
    }
}
</style>