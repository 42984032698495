<template>
<div class="complaint-list">
    <div class="search-bar">
        <div class="p-input-icon-left search-field">
            <i class="pi pi-search" />
            <InputText
                type="text"
                placeholder="Find by tour code"
            />
        </div>
        <Button v-if="can('create', 'complaint')" label="New Complaint" @click="openComplaintFormDialog('add')" class="p-button-sm p-ml-3" style="width: 125px" />
    </div>

    <Dialog header="Complaint Form" v-model:visible="showComplaintFormDialog" :style="{width: '50rem'}" :modal="true">
        <ComplaintForm ref="ComplaintFormRef" :FormType="form_type" @RefreshComplaint="getComplaintData()" />
    </Dialog>

    <div class="content-section implementation">
        <div class="complaint-datatable card">
            <DataTable
                :value="complaints"
                responsiveLayout="scroll"
                scrollHeight="320px"
                v-model:selection="selectedComplaint" 
                selectionMode="single"
                dataKey="id"
            >
                <Column field="id" header="ID" :style="{'min-width':'70px'}"></Column>
                <Column field="tour_code" header="Tour code" :style="{'min-width':'190px'}"></Column>
                <Column field="complain_about" header="Complain about" :style="{'min-width':'130px'}"></Column>
                <Column field="status" header="Status" :style="{'min-width':'100px'}">
                    <template #body="slotProps">
                        <Tag class="custom-tag p-mr-2" v-if="slotProps.data.status == 'Investigating'" severity="warning" :value="slotProps.data.status.toUpperCase()"></Tag>
                        <Tag class="custom-tag p-mr-2" v-if="slotProps.data.status == 'Solved'" severity="success" :value="slotProps.data.status.toUpperCase()"></Tag>
                    </template>
                </Column>

                <Column v-if="can('update', 'complaint') || can('delete', 'complaint')" :exportable="false" :style="{'min-width':'90px', 'padding':'0.4rem 0.5rem', 'text-align':'right'}">
                    <template #body="slotProps">
                        <Button v-if="can('update', 'complaint')" icon="pi pi-pencil" class="p-button-rounded p-button-outlined p-button-success small-datatable-button p-mr-2" @click="openComplaintFormDialog('update', slotProps.index)" />
                        <Button v-if="can('delete', 'complaint')" icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger small-datatable-button p-mr-2" @click="confirmDeleteComplaint(slotProps.data)" />
                    </template>
                </Column>
            </DataTable>
            <ConfirmDialog></ConfirmDialog>
        </div>

        <div class="complaint-report card">
            <div class="p-fluid p-grid">
                <div class="p-col">
                    <span style="font-weight: 600"> Report: </span>
                </div>
            </div>
            <ScrollPanel style="width: 100%; height: 325px; margin-right: 10px;" class="custom-scrollbar">
                <div class="p-fluid p-grid">
                    <div class="p-col p-mr-3">
                        <p>
                            {{ selectedComplaint ? selectedComplaint.report : '' }}
                        </p>
                    </div>
                </div>
            </ScrollPanel>
        </div>
    </div>
</div>
</template>

<script>
import { ref, onMounted, nextTick } from 'vue';
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { useAbility } from '@casl/vue';
import ComplaintService from '../service/ComplaintService';
import ComplaintForm from '../components/ComplaintForm.vue';

export default {
    setup() {
        const { can } = useAbility()
        const confirm = useConfirm()
        const toast = useToast()
        let complaints = ref([])
        let selectedComplaint = ref()
        let form_type = ref('')
        const ComplaintFormRef = ref()
        const showComplaintFormDialog = ref(false)

        const complaintService = ref(new ComplaintService())

        onMounted(()=> {
            getComplaintData()
        })

        //================Method=================     
        const getComplaintData = () => {
            complaintService.value.getComplaints().then((data) => {
                if(!data.errorResponse) {
                    complaints.value = data;
                }
            });
        }

        const openComplaintFormDialog = (formtype, index) => {
            showComplaintFormDialog.value = true
            form_type.value = formtype
            
            if(formtype == 'update'){
                selectedComplaint.value = complaints.value[index]
                nextTick(() => {
                    ComplaintFormRef.value.editComplaint(complaints.value[index])
                })
            }
        }

        const confirmDeleteComplaint = (data) => {
            confirm.require({
                message: 'Are you sure you want to delete this complaint data?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    const complaint_id = data.id
                    complaintService.value.deleteComplaint(complaint_id).then((data) => {
                        if(!data.errorResponse){
                            complaints.value = complaints.value.filter(val => val.id !== complaint_id);
                        } else {
                            toast.add({severity:'warn', summary: 'Delete Error ' + data.status, detail: data.errorResponse, life: 5000});
                        }
                    })
                }
            });
        }

        return {
            can,
            complaints,
            selectedComplaint,

            showComplaintFormDialog,
            openComplaintFormDialog,
            form_type,
            ComplaintFormRef,
            getComplaintData,
            confirmDeleteComplaint,
        }
    },
    components: {
        ComplaintForm,
    }
}
</script>

<style lang="scss" scoped>
.complaint-datatable {
    height: 340px;
}
.complaint-report {
    height: 380px;
    padding: 10px 20px;
    text-align: justify;
    line-height: 1.5;
    overflow: auto;
}

</style>