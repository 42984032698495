import axios from '../axios.instance'

export default class ComplaintService {
    async getComplaints(){
        try {
            const res = await axios.get('/complaint')
            return res.data;
        } catch (error) {
            return error.response.data
        }
    }

    async addComplaint(data){
        try {
            if(data != ''){
                const res = await axios.post('/complaint', {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async updateComplaint(id, data) {
        try {
            if(id != 0){
                const res = await axios.put('/complaint/'+id, {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async deleteComplaint(id){
        try {
            if(id != 0) {
                const res = await axios.delete('/complaint/'+id)
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }
}